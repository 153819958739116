<template>
  <VBtn
    depressed
    x-large
    color="tt-blue white--text"
    class="custom-action-btn text-none"
    data-test="next-btn"
    :disabled="isDisabled || timeoutId > -1"
    @click="onClick"
  >
    <VProgressCircular
      v-if="timeoutId > -1"
      indeterminate
      color="tt-black--text"
      class="mr-2 mt-1"
    />
    {{ buttonText }}
    <VIcon
      v-if="!isLastPage"
      class="tt-black--text mx-2 mt-1"
      color="tt-white"
      x-small
      dark
    >
      fas fa-chevron-right
    </VIcon>
  </VBtn>
</template>

<script>
import { mapState } from 'vuex';
import { requestsSentCount } from '@/plugins/vuex/mutationTypes';

export default {
  name: 'ActionsSurvey',

  props: {
    isGreetingPage: {
      type: Boolean,
      default: false,
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeoutId: -1,
    };
  },
  computed: {
    ...mapState([requestsSentCount]),
    buttonText() {
      let buttonText = this.$t('goFurther');
      if (this.isLastPage) {
        buttonText = this.$t('finishSurvey');
      }
      if (this.isGreetingPage) {
        buttonText = this.$t('startSurvey');
      }
      return buttonText;
    },
  },
  methods: {
    onClick() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = -1;
      }
      if (this.requestsSentCount > 0) {
        this.timeoutId = setTimeout(() => { this.onClick(); }, 100);
        return;
      }
      this.$emit('move');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-action-btn.v-btn {
  font-size: 20px !important;
  letter-spacing: 0.73px !important;
  line-height: 24px !important;
  font-weight: 500;
}
</style>
